<template>

  <div class="table-row">
    <div>
      <span class="status-indicator" :class="[account.status]" />
      {{ status }}
    </div>
    <div class="flex-grow">
      {{ account.email }}
    </div>
    <div>
      {{ account.username }}
    </div>
    <div>
      {{ account.subscription.stripe ? account.subscription.stripe.product.name : 'Free' }}
    </div>
    <div>
      {{ account.subscription.credits }}
    </div>
    <div>
      {{ account.subscription.usage }}
      <br>
      <span class="text-sm text-gray-600">{{ Math.round((account.subscription.usage / planLimit) * 100) }}%</span>
    </div>
    <div>
      {{ account.subscription.overages }}
      <br>
      <span class="text-sm text-gray-600">{{ formatNumberAsUSD(account.subscription.overages * 0.02 ) }}</span>
    </div>
    <div>
      <span class="status-indicator" :class="[account.subscription.overagesAllowed ? 'on' : 'off']" />
      {{ account.subscription.overagesAllowed ? 'On' : 'Off' }}
    </div>
    <div class="timestamp">
      {{ formatTimestamp(account.insertedAt) }}
    </div>
  </div>

</template>

<script>

  import { mapState } from 'vuex'

  import useFilters from '@/composables/useFilters'

  export default {
    props: {
      account: {
        type: Object,
        required: true,
      },
    },
    setup() {
      const { formatTimestamp, formatNumberAsUSD, titleCase } = useFilters()
      return { formatTimestamp, formatNumberAsUSD, titleCase }
    },
    computed: {
      ...mapState('app', ['stripePlansSlugMap']),
      status() {
        return this.titleCase(this.account.status)
      },
      planLimit() {
        return Number.parseInt(this.stripePlansSlugMap[this.account.subscription.stripe ? this.account.subscription.stripe.product.metadata.productSlug : 'free'].metadata.maxEventsMonthly, 10)
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .status-indicator
    &.on
    &.active
      @apply bg-success-500

    &.off
    &.blocked
      @apply bg-danger-500

    &.denied
      @apply bg-danger-700

    &.pending
      @apply bg-warning-500

    &.invited
      @apply bg-blue-500

</style>
