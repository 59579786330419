<template>

  <template v-if="isBootstrapDataLoading || paginationList.isLoading">
    <div class="loading-icon-container">
      <LoadingIcon />
    </div>
  </template>

  <template v-else>

    <template v-if="paginationList.totalItems === 0">
      There are currently no accounts.
    </template>

    <template v-else>
      <div class="flex items-end mb-4">
        <strong>{{ paginationList.totalItems }} total accounts</strong>
        <span class="flex-grow" />
        <button
          @click="exportData"
          class="small tertiary"
          :disabled="!canExportData || isExportingData"
          :title="canExportData ? '' : 'This action is not supported in your browser. Please use Chrome.'"
        >
          <LoadingIcon size="small" class="mr-2" v-if="isExportingData" />
          Export All to CSV...
        </button>
      </div>
      <div class="table-container">
        <LoadingOverlay type="light" v-if="paginationList.isLoading" />
        <div class="table">
          <div class="table-header table-row">
            <div>STATUS</div>
            <div class="flex-grow">EMAIL</div>
            <div>USERNAME</div>
            <div>PLAN</div>
            <div>CREDITS</div>
            <div>USAGE</div>
            <div>OVERAGES</div>
            <div>PPA</div>
            <div class="timestamp">CREATED AT</div>
          </div>
          <AccountTableRow
            :key="account.id"
            :account="account"
            v-for="account in paginationList.currentPage"
          />
        </div>
      </div>
      <PaginationButtons :paginationListName="paginationListName" />
    </template>

  </template>

</template>

<script>

  import { mapState } from 'vuex'
  import { format, addMinutes } from 'date-fns'

  import useExportAsCSV from '@/composables/useExportAsCSV'

  import LoadingIcon from '@/components/utils/LoadingIcon.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'
  import AccountTableRow from '@/components/tables/AccountTableRow.vue'
  import PaginationButtons from '@/components/utils/PaginationButtons.vue'

  export default {
    components: {
      LoadingIcon,
      LoadingOverlay,
      AccountTableRow,
      PaginationButtons,
    },
    setup() {
      const { exportAsCSV, canExportData, checkIfCanExportData } = useExportAsCSV()
      return { exportAsCSV, canExportData, checkIfCanExportData }
    },
    computed: {
      ...mapState('app', ['isBootstrapDataLoading']),
      paginationList() {
        return this.$store.state.pagination[this.paginationListName]
      },
    },
    data() {
      return {
        isExportingData: false,
        paginationListName: 'accounts',
      }
    },
    created() {
      this.$store.dispatch('pagination/LOAD_PAGE', { listName: this.paginationListName })
    },
    methods: {
      exportData() {

        if (!this.checkIfCanExportData()) return null

        this.isExportingData = true

        return this.$store.dispatch('pagination/GET_ALL_ITEMS', { listName: this.paginationListName })
          .then((response) => {

            const lines = response.data.map((account) => {

              // use addMinutes here to add in the UTC offset so the date is in
              //  UTC without an explicit timezone offset in the string
              const date = new Date(account.insertedAt)
              const formattedDate = format(addMinutes(date, date.getTimezoneOffset()), 'MM/dd/yyyy h:mm a')

              // @NOTE: none of these fields should have double-quotes in
              //  them, but if they did we would need to replace any
              //  instaces of " with ""

              return `"${formattedDate}", "${account.id}", "${account.username}", "${account.email}", "${account.status}"`

            })

            lines.unshift('CREATED AT, ID, USERNAME, EMAIL, STATUS')

            return this.exportAsCSV('dispatch-accounts.csv', lines.join('\n'))
              .then((didSaveFile) => {
                if (!didSaveFile) return
                this.$store.dispatch('toast/CREATE_TOAST', {
                  text: 'Successfully exported accounts!',
                  type: 'success',
                })
              })

          })
          .catch((error) => {
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: `Could not export accounts: ${error.message}`,
              type: 'error',
            })
          })
          .finally(() => {
            this.isExportingData = false
          })

      },
    },
  }

</script>

<style lang="stylus" scoped>

  .loading-icon-container
    @apply my-16
    @apply flex
    @apply justify-center

</style>
